<template>
  <div class="CartBillingView">
    <router-view />
    <div class="my-cart-view-container">
      <BillingDetailsView />

      <router-link class="o-default-body-xs nav-button" :to="links.summary">
        <md-button class="md-raised md-primary continue-btn">{{ $t('common.continue') }}</md-button>
      </router-link>
    </div>
  </div> <!-- /.CartBillingView -->
</template>

<script>
import BillingDetailsView from '@/views/BillingDetailsView'; // @todo - to be replaced with component

export default {
  name: 'CartBillingView',
  components: {
    BillingDetailsView
  },
  data() {
    return {
      links: {
        summary: '/cart/summary',
      }
    }
  }
};
</script>

<style lang="scss">
.CartBillingView {

  .continue-btn .md-button-content {
      font-size: $type-scale-size-6;
    }
}
</style>

<style lang="scss">
.CartBillingView {

  .o-container {
    max-width: 80rem;
    width: 100%;
  }

  .my-cart-view-container {
    width: 100%;
  }

  .continue-btn {
    display: block;
    margin: 2rem auto 4rem;
    min-height: 4.8rem;
    width: 100%;
  }
}
</style>